@import './theme.scss';
$box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
    0px 2px 2px 0px rgb(0 0 0 / 14%), 
    0px 1px 5px 0px rgb(0 0 0 / 12%);

@font-face {
    font-family: 'Karla';
    src: url('../../src/fonts/Karla/Karla-Regular.ttf');
}
.theme--dark {
    .img.dbxen {
        background-image: url('../photos/white_dbxen.svg');
    }
    .img.dbxenft {
        background-image: url('../photos/icons/dbxenft.svg');
    }
}

.theme {
    .img {
        width: 50%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .img.dbxenft {
        background-size: contain;
    }
}

.app-container {
    @include themed() {
        header {
            background-color: t('bg');
            flex-direction: row;
            align-items: center;
            box-shadow: none;
            .MuiButton-contained {
                background-color: t('bg');
                color: t('text');
                border: 1px solid t('text');
            }
            img {
                max-width: 175px;
                width: 100%;
            }
        }
        .main-menu--left {
            color: #fff;
            font-size: 1.5vh;
            padding: 15px 0;
            text-align: center;
        }
        .burn-container {
            margin-bottom: 2vh;
            margin-left: 0;
            margin-right: 0;
            max-width: 45vw;
            align-self: center;
            .row {
                width: 100%;
                padding-bottom: 10px;
                p {
                    color: t('text');
                }
            }

            .count-btn {
                font-size: 1.5vh;
                color: t('approve-stake-text');
                background-color: t('bg');
                border: 2px solid #F5B700;
                border-radius: 33px;
                box-shadow: none;
                padding: 7px 40px;
                white-space: nowrap;
                align-items: center;
            }

            .input-col {
                padding: 0;
                input {
                    align-items: center;
                    margin: auto 0;
                    border-radius: 33px;
                    padding: 7px 10px;
                    background-color: t('bg');
                    border: 1px solid #F2E9DC;
                    color: t('text');
                    text-align: center;
                    width: 100%;
                }
    
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
    
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            .values-container {
                width: 100%;
                .value-content {
                    display: flex;
                    justify-content: space-between;
                    color: #fff;
                    font-size: 1.5vh;
                    border-bottom: 1px solid #B7B7CE;
                    p {
                        color: #B7B7CE;
                        margin: 5px 0;
                    }
                }
            }
        }

        .dbxenft-menu {
            height: 80%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            a {
                color: #ECBA3F;
                font-weight: 800;
                font-size: 18px;
                letter-spacing: 0.5px;
            }
        }

        .burn-btn {
            font-size: 16px;
            background-color:  #F5B700;
            color: #000;
            font-weight: bold;
            border-radius: 25px;
            width: 150px;
            text-transform: none;
            z-index: 9;
            width: 100%;
            margin-top: 25px;
            .MuiLoadingButton-loadingIndicator {
                color: black;
                margin: 0 0 0 5px;
            }
            svg {
                height: 28px;
            }
            
        }
    }
}

@media (max-width: 768px) {
    .app-container {
        @include themed() {
            header {
                padding: 5px;
                justify-content: flex-end;
            }
            .image-container {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                position: fixed;
                bottom: 6px;
                z-index: 1201;
                left: 50%;
                transform: translateX(-50%);
                .img {
                    height: 100%;
                    background-size: contain;
                }
            }
            .side-menu {
                .MuiDrawer-paper {
                    flex-direction: row;
                    background-color: t('bg');
                    .Mui-selected {
                        background-color: transparent;
                        svg {
                            color: #1A0A11;
                        }
                    }
                    ul {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        .list-item {

                            .icon {
                                justify-content: flex-start;
                                width: 100%;
                            }
                            &:nth-last-child(-n + 2) .icon {
                                justify-content: flex-end;
                            }
                            .text {
                                display: none;
                            }
                        }
                    }
                }
            }

            .burn-container {
                width: 100%;
                max-width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                margin: 2vh;
                align-self: unset;
                .row {
                    margin: 0 auto;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .app-container {
        @include themed(){
            header {
                justify-content: space-between;
                .main-menu--right {
                    position: absolute;
                    right: 0;
                    display: flex;
                    padding: 5px 10px;
                    & > * {
                        margin: 0 5px;
                    }
                    form {
                        border: 1px solid t('text');
                        display: flex;
                        align-items: center;
                        input {
                            margin: 0 5px;
                        }
                    }
                }
            }
            .side-menu-box {
                width: 100%;
                height: 100%;
                .side-menu {
                    width: 100%;
                    .MuiDrawer-paperAnchorLeft {
                        border-bottom-left-radius: 100px;
                        border-top-left-radius: 60px;
                        background-color: #040433;
                        position: static;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        .image-container {
                            height: 15%;
                            background-color: t('logo-bg');
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: 4px 0px 13px #1A152033;
                            img {
                                max-width: 159px;
                                width: 100%;
                                height: 50px;
                                object-fit: cover;
                            }
                        }
                        .MuiTypography-root {
                            font-size: 18px;
                            font-family: 'Karla';    
                        }                        
                    }
                }
                .side-menu--bottom {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 10px;
                }
                .content {
                    margin-top: 15px;
                    display: flex;
                    padding-bottom: 10px;
                    align-items: center;
                    flex-direction: column;
                    .logo-text-color {
                        color: t(git-hub-logo);
                        img {
                            opacity: 0.2509803922;
                            height: 24px;
                        }
                    }
                    .social-media {
                        flex-direction: row;
                        a {
                            margin-right: 10px;
                        }
                    }
                    .contracts {
                        padding-top: 10px;
                        .row {
                            display: flex;
                        }
                        span, a {
                            color: #B7B7CE;
                            font-size: 12px;
                            display: flex;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

