@import './theme.scss';

.app-container {
    @include themed() {
        .app-bar--top {
            margin: 15px 45px 15px 15px;
            height: 4vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                display: none;
            }
            .main-menu--right {
                .MuiButton-contained {
                    font-family: 'Karla';
                    padding: 5px 25px;
                    border-radius: 25px;
                    background-color: t('bg');
                    color: t('text');
                    border: 1px solid #19D2D2;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
                button {
                    margin: 0 5px;
                }
                .component-switcher {
                    font-family: "Karla";
                    padding: 5px 15px;
                    border-radius: 25px;
                    background-color: #030328;
                    color: #F2E9DC;
                    border: 1px solid #19D2D2;
                    width: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                        max-height: 15px;
                        width: auto;
                    }
                }
            }
            .main-menu--left {
                display: flex;
                align-items: center;
                p {
                    display: none;
                }
                .donate-btn {
                    font-family: "Karla";
                    padding: 5px 15px;
                    border-radius: 25px;
                    background-color: #19D2D2;
                    color: #030328;
                    border: 1px solid #19D2D2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    font-weight: 800;
                    z-index: 1  ;
                    img {
                        max-height: 15px;
                        width: auto;
                        margin-left: 10px;
                    }
                    .donate-input {
                        z-index: 9999;
                    }
                }
            }
        }
        .back-button {
            background-color: transparent;
            color: #ECBA3F;
            display: flex;
            align-items: center;
            img {
                margin-right: 5px;
                width: 15px;
            }
        }
        @media (max-width: 768px) {
            .app-bar--top {
                height: auto;
                margin: 15px;
                flex-direction: column;
                .logo {
                    display: block;
                    width: 25vw;
                }
                .main-menu--right {
                    width: 100%;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .MuiButton-contained {
                        width: 100%;
                        font-size: 4vw;
                        padding: 5px 10px;
                    }
                    .language-switcher-container {
                        position: absolute;
                        top: 25px;
                        right: 10px;
                        width: 15%;
                        .language-switcher {
                            font-size: 2vh;
                            padding: 5px;
                            width: 100%;
                        }
                        
                    }
                    & button {
                        margin: 6px 0;
                    }

                    & button.chain-switcher {
                        font-size: 4vw;
                        padding: 5px 10px;
                    }
                    
                }
                .main-menu--left {
                    display: block;
                    font-size: 3.5vw;
                    padding: 15px 0;
                    display: flex;
                    flex-direction: column-reverse;
                    p {
                        display: block;
                    }
                    .donate-btn {
                        position: absolute;
                        top: 25px;
                        left: 10px;
                        font-size: 2vh;
                        margin: 6px 0;
                    }
                }
            }
        }
    }
}

.popper {
    z-index: 1203;
    width: 250px;
    padding: 0 15px 15px 15px;
    margin-top: 15px !important;
    border-radius: 25px;
    ul{
        margin-top: 15px;
        list-style: none;
        padding-left: 0;
        li {
            padding: 10px 0;
            border-bottom: 2px solid;
            span {
                float: right;
                font-weight: normal
            }
        }
        

    }
    &.classic {
        background-color: #ffeee8;
        box-shadow: 0px 3px 15px #1A152054;
        ul {
            color: #19141f;
            li {
                border-color: #f2e2dc;
            }
        }
    }
    &.dark {
        background-color: #051645;
        box-shadow: 0px 3px 15px #0f0b1373;
        ul {
            color: #fff;
            li {
                border-color: #19D2D2;
            }
        }
    }
    background-color: transparent;
    ul.MuiList-root{
        padding-top: 0;
    }
    .theme-select {
        .form-select {
            font-size: 14px;
            border: 1px solid #000;
            padding: 0 15px;
            background-position: right 0.4rem center;
        }
    }
    .logout-btn, .add-token-btn, .copy-wallet-btn {
        width: 100%;
        padding: 5px 0px;
        justify-content: left;
        font-weight: bold;
        text-transform: none;
        text-align: left;
        color: #CE4760;
        border: none;
        span {
            margin-right: 10px;
        }
    }
    .add-token-btn {
        color: #7f9acd;
    }
    .copy-wallet-btn {
        color: #91c499;
    }
}

.modal-box--donate {
    background-color: #030328;
    height: 470px;
    width: 100%;
    max-width: 750px;
    margin-left: 50%;
    transform: translate(-50%, 40%);
    border-radius: 60px;
    box-shadow: 0 0 15px #19D2D2;
    color: #fff;
    font-family: "Karla";
    display: flex;
    justify-content: center;
    .modal-body {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .content-top {
            padding-top: 50px;
            text-align: center;
            p {
                color: #F5B700;
                font-weight: 700;
                margin: 0;
                font-size: 24px;
            }
            .pizza {
                max-width: 70px;
                margin: 0 10px;
            }
        }
        .content-bottom {
            text-align: center;
            padding-bottom: 50px;
            .qrcode {
                max-width: 150px;
            }
            .address {
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    margin-bottom: 0;
                }
                .copy-wallet-btn {
                    justify-content: left;
                    min-width: auto;
                    .copy {
                        width: 16px;
                    }
                }
            }
        }
        .arrow {
            width: 50px;
            display: inline-block;
            position: relative;
            -moz-animation: bounce 0.5s infinite linear;
            -o-animation: bounce 0.5s infinite linear;
            -webkit-animation: bounce 0.5s infinite linear;
            animation: bounce 0.5s infinite linear;
            text-align: center;
        }
        @-webkit-keyframes bounce {
            0% { top: 0; }
            50% { top: -0.2em; }
            70% { top: -0.3em; }
            100% { top: 0; }
        }
        @-moz-keyframes bounce {
            0% { top: 0; }
            50% { top: -0.2em; }
            70% { top: -0.3em; }
            100% { top: 0; }
        }
        @-o-keyframes bounce {
            0% { top: 0; }
            50% { top: -0.2em; }
            70% { top: -0.3em; }
            100% { top: 0; }
        }
        @-ms-keyframes bounce {
            0% { top: 0; }
            50% { top: -0.2em; }
            70% { top: -0.3em; }
            100% { top: 0; }
        }
        @keyframes bounce {
            0% { top: 0; }
            50% { top: -0.2em; }
            70% { top: -0.3em; }
            100% { top: 0; }
        }
    }
}
