.table-view {
    width: 100%;
    height: 75vh;
    p {
        margin-bottom: 0;
    }
    // display: none;
    .empty-list {
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table {
        background-color: transparent;
        color: #fff;
        font-family: "Karla";
        --bs-table-bg: transparent;
        --bs-table-color: #fff;
        thead {
            color: #62CFD0;
            tr {
                th {
                    border-bottom: 0;
                    text-align: center;
                    padding-bottom: 25px;
                    white-space: nowrap;
                    font-weight: 400;
                }
            }
        }
        tbody {
            color: #fff;
            tr {
                border-bottom: 1px solid #3F10CD;
                &:last-child {
                    border-bottom: 0;
                }
                td {
                    border: 0;
                    vertical-align: middle;
                    text-align: center;
                    white-space: nowrap;
                }
                button {
                    padding: 5px 15px;
                    border-radius: 25px;
                    background-color: #19D2D2;
                    color: #030328;
                    text-transform: uppercase;
                    border: 0;
                    font-size: 12px;
                }
            }
        }
    }
    .xenft-details-row {
        .detailed-view {
            height: 100%;
            width: 100%;
            font-family: "Manrope";
            padding: 15px 0;
            .nft-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .nft-id {
                    font-size: 30px;
                }
                .nft-details {
                    font-size: 14px;
                }
            }
            .xenft-container {
                .xenft-details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 15px;
                    .img-container {
                        width: 20%;
                        img {
                            width: 100%;
                        }
                    }
                    .details-container {
                        font-size: 14px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .label {
                            opacity: 0.4;
                        }
                    }
                }
                .power {
                    color: #62CFD0;
                    .label {
                        opacity: 0.4;
                        font-size: 14px;
                    }
                    .value {
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                .burn-button-container {
                    .burn-button.btn {
                        border: 1px solid #62CFD0;
                        &:hover {
                            background-color: transparent;
                            color: #fff;
                        }
                        .MuiLoadingButton-loadingIndicator {
                            left: 45%;
                        }
                    }
                }
            }
        }
    }
}

.content-box.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container-nft {
    height: 75vh;
    width: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    .upper-container {
        display: flex;
        flex-direction: column;
        height: 90%;
        align-items: center;
        justify-content: flex-end;
    }
    .text-down {
        opacity: 0.5;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 16px;
        padding: 0 0 0 15px;
    }
    .image-container-nft {
        img {
            width: 35vh;
            padding-top: 10px;
        }
    }
    a {
        color: #19D2D2;
        &:hover {
            color: #19D2D2;
        }
    }

    .card {
        width: 50%;
        background-color: #051645;
        .card-text {
            padding: 10px 0;
        }
        .btn {
            font-family: "Karla";
            padding: 5px 15px;
            border-radius: 25px;
            background-color: #19D2D2;
            color: #030328;
            border: 1px solid #19D2D2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 800;
            z-index: 1  ;
        }
    }
}

.theme--dark {
    .app-container {
        .main-container {
            .content-box.content-box-table {
                overflow: auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .text-container-nft {
        height: 50vh;
        overflow-y: auto;

        .upper-container {
            height: unset;
        }

        .text-down {
            font-size: 12px;
            padding: 15px 0 0 15px;
        }

        .card {
            width: 80%;
        }
    }
    .table-view {
        height: 55vh;
        .xenft-details-row {
            td {
                display: block;
            }
            .detailed-view {
                .xenft-container {
                    .xenft-details {
                        display: -webkit-inline-box;
                    }
                }
            }
        }
    }
}